/* eslint-disable eqeqeq */
import CryptoJS from "crypto-js";
import {
  msisdn_data,
  circle_id,
  parent_msisdn,
  user_name,
  hash_msdin,
  ota_version,
  selected_profile,
  last_recharge_pack,
  type,
  utility_benefit,
  spin_type,
  vishop_parameters,
  last_transaction_available,
  wheel_entry_point,
} from "./constant";

const key = process.env.REACT_APP_SECURITY_KEY;
// const keyCms = process.env.REACT_APP_SECURITY_KEY_CMS;

export function backToAppDashboard() {
  const entry_type = localStorage.getItem(type);
  localStorage.removeItem("canSpin");
  if (window.ReactNativeWebView) {
    if (entry_type === "UTILITY_PAYMENTS") {
      window.ReactNativeWebView.postMessage("utility");
      // this check is added to redirect to vishop dashboard
    } else if (entry_type === "VISHOP") {
      window.ReactNativeWebView.postMessage("vishop");
    } else {
      window.ReactNativeWebView.postMessage("dashboard");
    }
  }
}

export const getMsisdnData = (linkDatas) => {
  let msisdn;
  let parentMsisdn;
  let circleId;
  let name;
  let provider;
  let subscriptionType;
  let otaVersion;
  let selectedProfile;
  let lastRechargePack;
  let utilityType;
  let utilityBenefit;
  let spinType;
  let vishopParameters;
  let isAnyLastTransactionAvailable;
  let wheelEntryPoint;
  if (linkDatas) {
    let linkData = decodeURIComponent(linkDatas);
    let bytes = CryptoJS.AES.decrypt(linkData, key);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    msisdn = JSON.parse(decryptedData.msisdn);
    localStorage.setItem(msisdn_data, msisdn);

    parentMsisdn = JSON.parse(decryptedData.parentMsisdn);
    localStorage.setItem(parent_msisdn, parentMsisdn);

    circleId = decryptedData?.circleId;
    localStorage.setItem(circle_id, circleId);

    name = decryptedData?.name;
    localStorage.setItem(user_name, name);

    otaVersion = decryptedData?.ota_version;
    localStorage.setItem(ota_version, otaVersion);

    lastRechargePack = decryptedData?.lastRechargePack;
    localStorage.setItem(last_recharge_pack, lastRechargePack);

    utilityType = decryptedData?.type;
    localStorage.setItem(type, utilityType);

    utilityBenefit = decryptedData?.utility_benefit;
    localStorage.setItem(utility_benefit, utilityBenefit);

    spinType = decryptedData?.spinType;
    localStorage.setItem(spin_type, spinType);

    vishopParameters = decryptedData?.vishop_parameters;
    localStorage.setItem(vishop_parameters, vishopParameters);

    isAnyLastTransactionAvailable = decryptedData?.isAnyLastTrasactionAvailable;
    localStorage.setItem(
      last_transaction_available,
      isAnyLastTransactionAvailable
    );

    wheelEntryPoint = decryptedData?.wheelEntryPoint;
    localStorage.setItem(wheel_entry_point, wheelEntryPoint);

    selectedProfile = decryptedData?.selectedProfileMSisdn;
    if (!selectedProfile) {
      localStorage.setItem(selected_profile, "");
    } else {
      localStorage.setItem(selected_profile, selectedProfile);
    }
    if (msisdn.length >= 11 && msisdn.match(/^[a-z0-9]+$/i)) {
      localStorage.setItem(hash_msdin, msisdn);
    } else {
      localStorage.setItem(hash_msdin, "");
    }
  }
  return { msisdn, parentMsisdn, circleId, name, provider, subscriptionType };
};

export const setDataFromApp = (data) => {
  const appData = data?.data;
  let AppData = decodeURIComponent(appData);
  let rewardBytes = CryptoJS.AES.decrypt(AppData, key);
  return JSON.parse(rewardBytes.toString(CryptoJS.enc.Utf8));
};

// Check if the user agent string indicates a webview on iPhone
function isIosWebView() {
  var userAgent = navigator.userAgent;

  // Check for specific iOS user agent patterns indicating a webview
  var iOSWebviewPattern = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i;
  return iOSWebviewPattern.test(userAgent);
}

// Check if the user agent string indicates a webview on Android
function isAndroidWebView() {
  var userAgent = navigator.userAgent;

  // Check for specific Android user agent patterns indicating a webview
  var androidWebviewPattern =
    /(Android).*Version\/[\d.]+.*Chrome\/[^\s]+ Mobile/i;
  return androidWebviewPattern.test(userAgent);
}

export function isWebView() {
  if (window.location.href.includes("localhost")) {
    return true;
  } else {
    // Usage
    if (isIosWebView()) {
      return true;
    } else if (isAndroidWebView()) {
      return true;
    } else {
      return false;
    }
  }
}

export function hideMobileNumber(number) {
  var hiddenNumber = "";

  // Extract last four digits
  var lastFourDigits = number.slice(-4);

  // Replace all other digits with asterisks
  for (var i = 0; i < number.length - 4; i++) {
    hiddenNumber += "*";
  }

  // Append the last four digits
  hiddenNumber += lastFourDigits;

  return hiddenNumber;
}

export function encrypt(str) {
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const enc = CryptoJS.AES.encrypt(str, keyutf, { iv: iv });
  const encStr = enc.toString();
  return encStr;
}

export function encryptObj(obj) {
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const enc = CryptoJS.AES.encrypt(JSON.stringify(obj), keyutf, { iv: iv });
  const encStr = enc.toString();
  return encStr;
}

export function decrypt(str) {
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const dec = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(str) },
    keyutf,
    {
      iv: iv,
    }
  );
  const decStr = CryptoJS.enc.Utf8.stringify(dec);
  return decStr;
}

export function decryptObj(obj) {
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const dec = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(obj) },
    keyutf,
    {
      iv: iv,
    }
  );
  const decStr = CryptoJS.enc.Utf8.stringify(dec);
  const converted = JSON.parse(decStr);
  return converted;
}

export const isOnline = () => {
  if ("connection" in navigator) {
    const connection = navigator.connection;
    if (connection?.downlink == 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
